<template>
  <div>
    <v-row>
      <v-col>
        <img src="../assets/login-logo.png" class="block-img" />
      </v-col>
      <v-col class="center-card">
        <v-card max-width="573" max-height="608" class="card-login" outlined>
          <div>
            <!-- <img src="../assets/ila-login.png" /> -->
            <img src="../assets/CDG-login.png" />
          </div>
          <v-card-text>
            <div class="align-block">
              <p class="bienvenu" v-if="clickForget">
                Mot de passe oublié
              </p>
              <p class="bienvenu" v-else>
                Bienvenue.
              </p>
              <p class="info-msg" v-if="clickForget">
                Veuillez saisir votre adresse email afin de recevoir le lien de
                réinitialisation de votre mot de passe
              </p>
              <p class="info-msg" v-else>
                Entrez votre adresse email et mot de passe pour vous connecter
              </p>
            </div>
            <!-- ENVOI EMAIL -->
            <div class="align-block" v-if="clickForget">
              <p class="label">Adresse email</p>
              <v-text-field
                label=""
                :persistent-placeholder="true"
                v-model="email"
                @input="resetError"
                :rules="emailRules"
                :error-messages="errorMessageEmail"
                outlined
                class="msg-error"
                ref="email"
                color="#704ad1"
              >
              </v-text-field>
              <v-row>
                <v-col cols="6">
                  <v-btn
                    color="#704ad1"
                    class="white--text btn-connexion"
                    @click.prevent="submitForgotPassword"
                  >
                    Envoyer
                  </v-btn></v-col
                >
                <v-col>
                  <div class="back pt-1" @click="backLogin" v-if="clickForget">
                    Retour à la page de connexion
                  </div>
                </v-col>
              </v-row>
            </div>
            <!-- LOGIN -->
            <div class="align-block" v-else>
              <v-form @keyup.enter.native="Connexion">
                <p class="label">Adresse email</p>
                <v-text-field
                  label=""
                  :persistent-placeholder="true"
                  v-model="user.email"
                  :rules="emailRules"
                  @input="resetErrorLogin"
                  outlined
                  class="msg-error"
                  color="#704ad1"
                >
                </v-text-field>
                <p class="label">Mot de passe</p>
                <v-text-field
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  label=""
                  outlined
                  v-model="user.password"
                  @input="resetErrorLogin"
                  class="msg-error"
                  :rules="[rules.required]"
                  @click:append="show = !show"
                  color="#704ad1"
                ></v-text-field>
                <v-btn
                  color="#704ad1"
                  class="white--text btn-connexion"
                  :loading="getloginLoding"
                  @click.prevent="Connexion"
                >
                  <font-awesome-icon icon="lock" class="mr-2" />
                  Connectez
                </v-btn>
              </v-form>

              <v-row class="pt-2 ">
                <v-col cols="5" class="d-flex">
                  <v-checkbox
                    class="checked-global"
                    color="#704ad1"
                    :value="true"
                    :true-value="true"
                    :false-value="false"
                    hide-details
                  >
                    <template v-slot:label>
                      <span> Se sourvenir de moi</span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="6">
                  <div class="forget" @click="handleInterfaceForgetPassword">
                    Mot de passe oublié ?
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-text>
            <div class="error-msg" v-if="getloginError">
              {{ getloginError }}
            </div>
          </v-card-text>
          <v-card-text>
            <div class="error-msg" v-if="error">
              {{ error }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <FlashMessage
      :position="'right bottom'"
      class="flach-Message-index"
    ></FlashMessage>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      user: {
        email: null,
        password: null
      },
      errorMessageEmail: [],
      email: null,
      clickForget: false,
      show: false,
      error: null,
      success: null,
      rules: {
        required: value => !!value || 'Mot de passe est obligatoire.'
      },
      emailRules: [
        v => !!v || 'E-mail est obligatoire.',
        v => /.+@.+\..+/.test(v) || 'Format E-mail invalid.'
      ]
    };
  },
  methods: {
    ...mapActions(['login', 'forgotPassword', 'resetErrorLogin']),
    async submitForgotPassword() {
      this.resetErrorLogin();
      this.error = null;
      if (this.$refs.email.validate()) {
        let formData = new FormData();
        formData.append('email', this.email);
        const response = await this.forgotPassword(formData);
        if (response.success) {
          this.flashMessage.show({
            status: 'success',
            title: '',
            clickable: false,
            time: 3000,
            message: response.response
          });
          this.backLogin();
        } else {
          this.error = response.error;
        }
      } else {
        this.errorMessageEmail = this.$refs.email.errorBucket;
      }
    },
    resetError() {
      this.error = null;
    },
    handleInterfaceForgetPassword() {
      this.resetErrorLogin();
      this.clickForget = true;
    },
    backLogin() {
      this.resetErrorLogin();
      this.error = null;
      this.clickForget = false;
    },
    Connexion() {
      this.login(this.user);
    }
  },
  computed: {
    ...mapGetters(['getloginLoding', 'getloginError'])
  }
};
</script>
<style lang="scss" scoped>
._vue-flash-msg-body_success._vue-flash-msg-body._vue-flash-msg-_right-bottom {
  width: 12% !important;
}
.error-msg {
  color: red;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}

.success-msg {
  color: green;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}
</style>

<style lang="scss">
.block-img {
  height: 87%;
  width: 100%;
}
.center-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.card-login {
  .align-block {
    margin-left: -15px;
  }
  .bienvenu {
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: bold;
    color: #704ad1;
  }
  .info-msg {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    color: #3c454b;
  }
  .label {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #3a167f;
    margin-bottom: 9px !important;
  }
  .back {
    margin-bottom: 0px !important;
    margin-top: 21px;
    color: #3c454b;
    padding-left: 10px;
    margin-left: 67px;
    font-size: 12px;
    font-family: 'Montserrat';
    font-weight: 600;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .footer-back {
    float: right;
    margin: 0px 9px 0px 0px;
  }
  .forget {
    border-left: 1px solid;
    margin-bottom: 0px !important;
    margin-top: 21px;
    color: #3c454b;
    padding-left: 10px;
    margin-left: -18px;
    font-size: 12px;
    text-decoration: underline;
    font-family: 'Montserrat';
    font-weight: 600;
    cursor: pointer;
  }
  .checkbox-name {
    margin-top: 21px;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    color: #3c454b;
  }
}
.card-login.v-card.v-sheet.v-sheet--outlined.theme--light {
  border: none !important;
}
button.white--text.btn-connexion.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}
.vertical-divider {
  background-color: black;
  max-width: 2px;
  width: 2px;
  height: 23px;
  min-height: 23px;
  max-height: 23px;
  margin-top: 20px;
  margin-left: -30px;
}
</style>
